import React, { useContext, useEffect, useState } from "react";
import { UserRoleContext } from '../UserRoleContext';
import PropTypes from 'prop-types';

import {
    ArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput,
    DateInput,
    Edit,
    ImageField,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from "react-admin";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { getEditions } from "../helpers/getEditions";
import { getYears } from "../helpers/getYears";
import categories from "../data/categories.json";
import series from "../data/series.json";
import languages from "../data/languages.json"
import binding from "../data/binding.json";
import condition from "../data/condition.json";

const years = getYears();

const ImageButton = () => (
    <Button variant="contained">Lisää kuva..</Button>
);

const isRequired = required("Kenttä on pakollinen.")
const ProductTitle = ({ record }) => {
    return <span>{record?.attributes?.find(attribute => attribute.id === 8) ? `${record.attributes.find(attribute => attribute.id === 8).options} / ${record.name}` : ''}</span>;
};
ProductTitle.propTypes = {
    record: PropTypes.object,
  };
const editions = getEditions();

export const AntikkaBatchProductEditView = props => {
    const { vendor } = useContext(UserRoleContext);
    const [userVendors, setUserVendors] = useState([]);

    useEffect(() => {
        if (vendor) {
            const vendorsList = vendor.name || [];
            const formattedVendors = vendorsList.map((vendorName, index) => ({
                id: index,
                name: vendorName
            }));
            setUserVendors(formattedVendors);
        }
    }, [vendor]);
    return (
    <Edit title={<ProductTitle />} {...props} >
        <SimpleForm
            variant="outlined"
        >
            <Grid container alignItems="flex-start" spacing={1} >
                
                <Grid item sm={12} xs={12} md={2}>
                        <DateInput 
                        fullWidth label="Julkaisupäivä" 
                        source="release_date" 
                        />
                        <AutocompleteInput 
                        label="Myyjä" 
                        source="batchVendor" 
                        optionValue="name" 
                        choices={userVendors} />
                    </Grid>
                    <Grid item sm={12} xs={12} md={2}>
                <ImageInput 
                    fullWidth 
                    variant="outlined" 
                    source="image"
                    label="Kuva"
                    accept="image/*"
                    placeholder={<ImageButton />}>
                            <ImageField variant="outlined" source="src" title="title" />
                        </ImageInput>
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                <RadioButtonGroupInput variant="outlined" label="Varaston tila" source="stock_status" choices={[
                        { id: 'instock', name: 'Myynnissä' },
                        { id: 'outofstock', name: 'Ei myynnissä' },
                    ]} />
                    
                </Grid>
                <Grid item sm={12} xs={12} md={4}>
                    <TextInput variant="outlined" validate={isRequired} label="Nimi" source="name" />
                    <TextInput variant="outlined" label="Tekijä" source="author" />
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                <AutocompleteArrayInput   
                    translateChoice={false}
                    label="Painovuosi" 
                    variant="outlined" 
                    source="year" 
                    choices={years}
                    />
                    <AutocompleteArrayInput
                    translateChoice={false}
                    variant="outlined" 
                    label="Painos" 
                    source="edition" 
                    choices={editions}
                    />
                    
                    
                </Grid>
            </Grid>
            
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={2}>
                    <TextInput 
                    variant="outlined" label="Kustantaja" source="publisher" />
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                <TextInput 
                    variant="outlined" 
                    label="Sivumäärä" 
                    source="pages" 
                    />
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                <NumberInput 
                    variant="outlined" 
                    validate={isRequired}  
                    label="Hinta (€)"  
                    options={{ style: 'currency', currency: 'EUR' }}
                    source="price" />
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <NumberInput 
                    validate={isRequired}  variant="outlined" min="0" initialValue="1" label="Määrä" source="stock_quantity" />

                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <ArrayInput fullWidth label={false}  source="categories">
                        <SimpleFormIterator 
                        fullWidth
                        disableReordering  disableAdd  >
                            <SelectInput
                                fullWidth 
                                validate={isRequired}  
                                label="Osasto"
                                variant="outlined"
                                optionValue="name"
                                source="name"
                                choices={categories}
                                />
                        </SimpleFormIterator>
                    </ArrayInput>

                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <AutocompleteArrayInput
                      
                    variant="outlined" 
                    optionValue="name" 
                    optionText="name"
                    label="Kieli" 
                    source="language" 
                    choices={languages}/>
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
            <Grid item sm={12} xs={12} md={3}>
                    <TextInput variant="outlined" multiline fullWidth  minRows={2} label="Kuvaus" source="description" />

                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <SelectInput 
                    variant="outlined" label="Sarja" optionValue="name" source="series" choices={series}/>
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <SelectInput 
                    variant="outlined" optionValue="name" label="Kuntoluokka" source="condition" choices={condition}/>
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <SelectInput
                    variant="outlined" 
                    optionValue="name" 
                    label="Sidosasu" 
                    source="layout"
                    choices={binding}
                    />
                </Grid>
                <Grid item sm={12} xs={12} md={2}>
                    <TextInput 
                    variant="outlined" label="Painopaikka" source="print_place" />
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                <TextInput variant="outlined" label="Varasto" source="storage" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput variant="outlined" label="Hylly" source="shelf" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput variant="outlined" label="ISBN" source="isbn" />
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <BooleanInput
                label="Lisätäänkö Antikvaariin?"
                source="targets" />
                </Grid>
            </Grid>               
        </SimpleForm>
    </Edit>
);}