import * as React from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Money from '@mui/icons-material/Money';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import PaperbaseTheme from '../themes/PaperbaseTheme';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { UserRoleContext } from '../UserRoleContext';

const vendorRoles = ['vendor', 'kirjapinoVendor', 'antikkaVendor', 'admin'];

const MyMenu = (props) => {
    const { role } = React.useContext(UserRoleContext);

    return (
        <Menu 
            sx={{ displayPrint: 'none' }}
            theme={PaperbaseTheme} 
            {...props}
        >
            {(vendorRoles.includes(role)) &&
                <MenuItemLink to="/orders" primaryText="Tilaukset" leftIcon={<Money />} />
            }
            {(role === 'vendor' || role === 'admin') &&    
                <MenuItemLink to="/products" primaryText="Kirjat" leftIcon={<MenuBookIcon />} />
            }
            {(role === 'antikkaVendor') &&    
                <MenuItemLink to="/products" primaryText="Kirjat" leftIcon={<MenuBookIcon />} />
            }
            <MenuItemLink to="/batch" primaryText="Eräajo" leftIcon={<AddToQueueIcon />} />
            {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&    
                <MenuItemLink to="/reports" primaryText="Eräajon Raportit" leftIcon={<SummarizeIcon />} />
            }
            {(role === 'vendor' || role === 'admin' || role === 'antikkaVendor') &&    
                <MenuItemLink to="/sales" primaryText="Myynnit" leftIcon={<MonetizationOnIcon />} />
            }
        </Menu>
    );
}

export default MyMenu;
