import React, { createContext, useState, useEffect } from 'react';
import { FirebaseAuthProvider } from 'react-admin-firebase';
import vendors from './config/vendors.json';
import { firebaseConfig as config } from './FIREBASE_CONFIG';

export const UserRoleContext = createContext();

const authProvider = FirebaseAuthProvider(config);

export const UserRoleProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({ role: null, vendor: null });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const user = await authProvider.checkAuth();
        const vendor = vendors[user.uid];
        const userRole = vendor.role;
        setUserInfo({ role: userRole, vendor });
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <UserRoleContext.Provider value={userInfo}>
      {children}
    </UserRoleContext.Provider>
  );
};