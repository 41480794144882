/* eslint-disable import/no-anonymous-default-export */

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import {  FirebaseAuthProvider } from "react-admin-firebase";
import { firebaseConfig as config } from '../FIREBASE_CONFIG';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, query, limitToLast, onValue, orderByChild, startAt, set } from 'firebase/database';
import {parseProductAfterGet, parseProductBeforePost} from '../helpers/parseProduct';
import axios from "axios";
import { getFolder } from "../helpers/getFolder";
import { createSalesReport } from "../helpers/createSalesReport";

const WooCommerceAPI = require('@woocommerce/woocommerce-rest-api').default;
//const basicAuth = 'ZWxhc3RpYzp4cFVXSjRqdFZRY0RWR1JobUJtRlBSZ3c='
const basicAuth = 'ZWxhc3RpYzoyOXdHOWY3ODRiWkM1ZGNyazBxajNaRTQ='
//const url = 'https://kirjapino-fi.es.europe-north1.gcp.elastic-cloud.com/'
const url = 'https://elastic.kirjapino.fi/'
const index = 'kirjapinofi-post-1'
const esMsgBody = require('../data/esQueryTemplate.json');
const productTemplate = require('../data/productTemplate.json');


const wcConfig = require('../config/wc.json');
const host = 'kirjapino';
const fetchVendor = 'planeetta';
const wooConfig = require('../config/woocommerce.json');
const vendorKeys = require('../config/vendorKeys.json');
const WooCommerce = new WooCommerceAPI(wcConfig[host][fetchVendor]);

const authProvider = FirebaseAuthProvider(config);


const vendors = require('../config/vendors.json');

// Initialize Firebase
const app = initializeApp(config);
const database = getDatabase(app);

function objectSort(object, sortingKey, sortOrder = "ASC") {
    const compareFunc = (a, b) =>
    a[sortingKey] < b[sortingKey] ? -1 : a[sortingKey] > b[sortingKey] ? 1 : 0;
  return object.sort((a, b) => (sortOrder === "ASC" ? compareFunc(a, b) : compareFunc(b, a)));
}

function allValuesTruthy(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === false) {
            return false;
        }
    }
    return true;
}

async function updateMultiVendorOrder(folder, multiVendorOrder) {

    const dataRef = ref(database, folder + '/' + multiVendorOrder.id);
    return new Promise(
        (resolve, reject) => {
            set(dataRef, multiVendorOrder)
            .then(() => {
                resolve({data: multiVendorOrder});
            })
            .catch((error) => {
                console.error(error);
                console.error(error.message);
                console.error(error.stack);
                reject (error);
            })
        });

}

function filterLineItemsByVendor(lineItems, vendorName) {
    // console.info('Filtering line items by vendor: ', vendorName);
    let filteredLineItems = [];
    for (let lineItem of lineItems) {
        for (let metaData of lineItem.meta_data) {
            if (metaData.value === vendorName[0] || metaData.value === vendorName) {
                filteredLineItems.push(lineItem);
                break; // Stop checking other meta_data for this lineItem
            }
        }
    }
    
    return filteredLineItems;
}


/**
 *
 * @param {string} resource
 * @param {Array} params
 * @return {Array}
 */
 export async function getList(resource, params) {
     // Firebase
    const user  = await authProvider.checkAuth();
    const vendorUid = user.uid;
    const vendor = vendors[vendorUid].name;
    const orderVendorName = vendors[vendorUid].orderName.toString();
    
    let folder;
    folder = await getFolder(resource, 'getList');
    // search from elasticsearch
    if (params.filter && resource === "products") {
        const query = params.filter.q ? params.filter.q : '';
        if (query.length >= 1) {
            
            const postAuthorId = vendors[vendorUid].postAuthorId;
            esMsgBody.query.bool.must[1].match["post_author.id"] = postAuthorId;
            esMsgBody.query.bool.must[0].query_string.query = query;           
            
            const options = {
                method: 'get',
                size: params.pagination.perPage,
                from: (params.pagination.page - 1) * params.pagination.perPage,
                url: url + index + '/_search',
                headers: {
              'Authorization': 'Basic ' + basicAuth,
              'Content-Type': 'application/json'
                },
                body: JSON.stringify(esMsgBody)
              };
            return new Promise(
                (resolve) => {
                  axios.get(options.url, {
                    headers: options.headers, 
                    params: {
                        from: options.from,
                        size: options.size,
                      source: options.body, 
                      source_content_type: 'application/json',
                      timeout: '10s'
                    },
                    timeout: 10000 // Timeout in milliseconds
                  }).then((response) => {
                    const body = response.data;
                const data = body.hits.hits;
                let pData = [];
                if (data.length > 0) {
                  for (let item of data) {
                  let product;
                    if (item._source.post_type === 'product') {    
                          item._source.attributes = productTemplate.attributes;
                          item._source.id = item._source.post_id
                          item._source.name = item._source.post_title;
                          item._source.images = [{src: null}]
                          const receivedImgSrc = item._source?.thumbnail?.src 
                          ?? 'https://kirjapino.fi/wp-content/uploads/2022/08/Empty_book-scaled.jpg';
                          
                          item._source.images[0].src = receivedImgSrc;
                          item._source.shelf = item._source.terms.pa_hylly ? item._source.terms.pa_hylly[0].name: '';
                          item._source.attributes[8].options[0] = item._source.shelf ? item._source.shelf.toString(): '';
                          item._source.sku = item._source.meta._sku ? item._source.meta._sku[0].value : '';
                          item._source.author = item._source.terms.pa_tekija ? item._source.terms.pa_tekija[0].name: '';
                          item._source.attributes[0].options[0] = item._source.author ?  item._source.author.toString() : '';
                          item._source.stock_status = item._source.meta._stock_status ? item._source.meta._stock_status[0].value : '';
                          item._source.stock_quantity = parseInt(item._source.meta._stock ? item._source.meta._stock[0].value : '');
                          item._source.price = parseFloat(item._source.meta._price ? item._source.meta._price[0].value : '');
                          item._source.date_created = item._source.post_date;
                          item._source.date_modified = item._source.post_modified;
                          product = item._source;
                  }
                  product !== undefined ? pData.push(product) : console.log('Product undefined: ',product);
                  
                  }
                const total = body.hits.total.value;
                pData = pData.slice(0, 10);
                resolve({data: pData, total: pData ? total  : 0, page: params.pagination.page})
              } 
                
             }).catch((error) => {
                console.error(error);
                console.error(error.message);
                console.error(error.stack);
                resolve({data: error, total: 0, page: 1});
         })
        })

        }
    } 
    
    const page= params.pagination.page;
    const perPage = params.pagination.perPage;
    const sortBy = params.sort.field;
    const sortOrder = params.sort.order;
    const limit = page * perPage; 
    const startingPoint = (page - 1) * params.pagination.perPage;
    const productQuery = query(ref(database, folder), startAt(startingPoint), limitToLast(limit + perPage), orderByChild(sortBy));
    const orderQuery = query(ref(database, folder), startAt(startingPoint), orderByChild(sortBy));
    let dataRef = query(ref(database, folder));
    switch (resource) {
        case "products":
            dataRef = productQuery;
            break;
        case "orders":
            dataRef = orderQuery;
            break;
        case "batch":
            dataRef = query(ref(database, folder));
            break;
        default:
            dataRef = query(ref(database, folder));
            break;
    }

    return new Promise(
        (resolve, reject) => {
            onValue(dataRef, (snapshot) => {
                    let values = snapshot ? snapshot.val() : [] || [];
                    values = values || [];
                    // const values = snapshotVal ? Object.values(snapshotVal) : [];
                    const isBatch = resource === "batch";
                    const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // Regular expression to match YYYY-MM-DD format

                    const keys = isBatch ? Object.keys(values) : [];
                    if (isBatch && keys.length > 0) {
                        // Filter out keys that match the date format
                        const filteredKeys = keys.filter(key => !dateRegex.test(key));
                        values = filteredKeys?.map(key => values[key]) || [];
                        } else if (!isBatch) {
                            values = Object.values(values) || [];
                        } 
                        if (resource === "sales") {
                            // Parse the child object
                            values = createSalesReport(snapshot);
                            values = Object.values(values) || [];
                            let sortedValues = objectSort(values, sortBy, sortOrder);
                            console.info('SALES:', {data: sortedValues})
                            resolve ({ data: sortedValues, total: sortedValues.length, page: 1 });
                        } /*else if (resource === 'reports') {
                            //const filteredKeys = keys.filter(key => !dateRegex.test(key));
                            values = values?.map(key => values[key]) || [];
                        }*/

                    let sortedValues = objectSort(values, sortBy, sortOrder);
                    // Filtering out orders without shipping lines
                    sortedValues = resource === 'orders' ? sortedValues.filter(child => child.shipping_lines && child.shipping_lines.length > 0) : sortedValues;
                    const total = resource === "products" ? 10000 : sortedValues.length;
                    for (let child of sortedValues) {
                        if (resource === "products" || resource === "batch") {                             
                              // Parse the child object  
                            child = parseProductAfterGet(child);
                            if (resource === "batch"){
                            child.published = child?.published ? child.published : false;
                            }
                        }
                        else if (resource === "orders") {
                            // Parse the child object
                            child.shippingMethod = child?.shipping_lines[0]?.method_id;
                            child.status = child?.multiVendorOrder && child?.multiVendorOrder[vendor] ? 'completed' : child.status;
                            child.line_items = filterLineItemsByVendor(child.line_items, orderVendorName);
                            child.vendorName = orderVendorName;
                        }
                    }  
                    console.log('startingPoint: ', startingPoint, 'limit: ', limit);
                    sortedValues = sortedValues.slice(startingPoint, limit);
                    console.log('sortedValues: ', sortedValues);
                    resolve ({data: sortedValues, total: total, page: page});
                }, (error) => {
                    console.error(error);
                    console.error(error.message);
                    console.error(error.stack);
                    
                    reject (error);

                }
            )
        });

    }

export async function getOne(resource, params) {
    const user  = await authProvider.checkAuth();
    const vendorUid = user.uid;
    const vendor = vendors[vendorUid].name;
    const vendorId = resource === 'products' ? vendors[vendorUid].postAuthorId : vendors[vendorUid].id;
    if (resource !== 'products'){
        // Firebase
    const orderVendorName = vendors[vendorUid].orderName;
    const orderVendorAddress = vendors[vendorUid].orderAddress;
    const orderVendorMail = vendors[vendorUid].vendorMail;
    const orderVendorPhone = vendors[vendorUid].vendorPhone;
    let folder = await getFolder(resource, 'getOne', params.id);

    const dataRef = query(ref(database, folder));
    return new Promise( 
        (resolve, reject) => {
            onValue(dataRef, async (snapshot) => {
                    if (resource === "batch") {
                        const batchProduct = snapshot.val();
                        // Ensure `product` is not null or undefined after parsing
                        const product = batchProduct && batchProduct?.id ? await parseProductAfterGet(batchProduct) : batchProduct;

                    if (product) {
                        // Set default value for published if it's undefined
                        product.published = product.published ?? false;
                        }
                        resolve ({data: product});
                    } else {
                    const order = snapshot.val()
                    const shippedVendors = order.meta_data?.find(mdItem => mdItem.key === "wc_pv_shipped")?.value || [];
                    let isShipped = false;
                    for (let shippedVendor of shippedVendors) {
                        if (vendorId === shippedVendor) {
                            isShipped = true;
                            break;
                        }
                        
                    }
                    for (let lineItem of order.line_items) {
                        lineItem.shipped = isShipped;
                    }
                    // Temporarily disabled filtering of line items by vendor
                    const filteredLineItems = filterLineItemsByVendor(order.line_items, orderVendorName);
                    const tempStatus = order.status;
                    order.status = order.multiVendorOrder[vendor] ? 'completed' : tempStatus;
                    order.line_items = filteredLineItems;
                    order.name = order.billing.first_name + ' ' + order.billing.last_name;
                    order.shippingName = order.shipping.first_name + ' ' + order.shipping.last_name;
                    order.vendorName = orderVendorName;
                    order.vendorAddress = orderVendorAddress;
                    order.vendorMail = orderVendorMail;
                    order.vendorPhone = orderVendorPhone;
                    console.info('ORDER:', {data: order})
                    resolve ({data: order})
                    };
                }, (error) => {
                    console.error(error);
                    console.error(error.message);
                    console.error(error.stack);
                    reject (error);

                }
            )
        });
    } else {
        // wordpress
    return WooCommerce.get(resource + '/' + params.id)
        .then(async (response) => {
            // Successful request
            const product = resource === 'products' ? await parseProductAfterGet(response.data) : response.data;
            console.info('PRODUCT:', {data: product})
            return { data: product };
            })
          .catch((error) => {
            // Invalid request, for 4xx and 5xx statuses
            console.error("Response Data:", error);
            console.error(error.message);
            console.error(error.stack);
            return { data: error };
          })
          .finally(() => {
            // Always executed.
          });
        }
    }


export async function getMany(resource, params){
    const idList = []
    for (let id of params.ids) {
        idList.push(id)
    }
    const payLoad = {include: idList}
    return WooCommerce.get(resource, payLoad)
        .then(async (response) => {
            // Successful request
            const products = []
            const respData = response.data
            for (let product of respData) {
                products.push(await parseProductAfterGet(product))
            }
            return { data: products };
            
          })
          .catch((error) => {
            // Invalid request, for 4xx and 5xx statuses
            console.error("Response Data:", error);
            return { data: error };
          })
          .finally(() => {
            // Always executed.
          });
}
export async function getManyReference(resource, params){
    return Promise.resolve(resource);
}
export async function  create(resource, params){
    const user  = await authProvider.checkAuth();
    const vendorUid = user.uid;
    if (resource === 'batch') {
        const published = params.data?.published ? params.data.published : false;
        const productId = params.data?.id ? params.data?.id : Date.now();
        params.data.vendor = params.data.batchVendor;
        const product = await parseProductBeforePost(params.data)
        product.id = productId;
        product.timestamp = productId;
        product.published = published;
        let date = new Date(params.data.release_date);
        let formattedDate = date.getFullYear()+'-'+('0' + (date.getMonth()+1)).slice(-2)+'-'+('0' + date.getDate()).slice(-2);
        params.data.release_date = formattedDate;
        product.release_date = params.data.release_date || Date.now();
        product.user = user?.displayName?.replace(/\s+/g, '-').toLowerCase() || user?.email?.split('@')[0].replace('.', '-').toLowerCase();
        const folder = await getFolder(resource, 'create', productId);
        const dataRef = ref(database, folder);
        return new Promise(
            (resolve, reject) => {
                set(dataRef, product)
                .then( () => {
                    const emptyProduct = productTemplate;
                    resolve({data: emptyProduct});
                })
                .catch((error) => {
                    console.error(error);
                    console.error(error.message);
                    console.error(error.stack);
                    reject (error);
                })
            });
    }
    
    params.data.vendor = vendors[vendorUid].name;
    wooConfig.consumerKey = vendors[vendorUid].consumerKey;
    wooConfig.consumerSecret = vendors[vendorUid].consumerSecret;
    const wooC = new WooCommerceAPI(wooConfig)
    const product = await parseProductBeforePost(params.data)
    delete product.id;
    return wooC.post(resource, product)
        .then(async (response) => {
            // Successful request
            const createdProduct = await parseProductAfterGet(response.data)
            
            return {
                data: createdProduct
            };
        })
        .catch((error) => {
            // Invalid request, for 4xx and 5xx statuses
            console.error("Response Data:", error);
            console.error(error.message);
            console.error(error.stack);
            return { data: error };
        })
        .finally(() => {
            // Always executed.
        });

}
export async function update(resource, params){
    // Wordpress tai api-kirjapino-fi
    const user  = await authProvider.checkAuth();
    // Orders cannot be updated by the vendor?
    let vendorUid = resource === "products" || resource === "batch" ? user.uid : "admin";
    const orderVendor = vendors[user.uid].name;
    const product = resource === "products" && resource !== "batch" 
    ? await parseProductBeforePost(params.data) 
    : {
        id: params.data.id, 
        billing: params.data.billing, 
        shipping: params.data.shipping, 
        status: params.data.status, 
        multiVendorOrder: params.data.multiVendorOrder
    };
    params.data.vendor = resource === "products" || resource === "batch" ? vendors[vendorUid].name : "admin";
    wooConfig.consumerKey = vendors[vendorUid].consumerKey;
    wooConfig.consumerSecret = vendors[vendorUid].consumerSecret;

    if (resource === "orders") {        
        if (product.multiVendorOrder){
            if (product.status === "completed") {
                const folder = '/kirjapino/orders/multivendorOrders/';
                delete product?.multiVendorOrder?.antikvaariId;
                 
                product.multiVendorOrder[orderVendor] = true;
                const updatedMultiVendorOrder = await updateMultiVendorOrder(folder, product.multiVendorOrder);
                product.multiVendorOrder = updatedMultiVendorOrder.data;
                               
                if (!allValuesTruthy(product.multiVendorOrder)) {
                product.status = "processing";
                console.warn("Not all vendors handled: ", { data: product.multiVendorOrder });
                }
            }
        }
        wooConfig.consumerKey = vendorKeys[params.data.vendor][params.data.source].consumerKey;
        wooConfig.consumerSecret = vendorKeys[params.data.vendor][params.data.source].consumerSecret;
        wooConfig.url = params.data.source;
        if (product.status === "trash") {
            const wooC = new WooCommerceAPI(wooConfig)
            return wooC.delete(resource + '/' + product.id, {force: true})
            .then((response) => {
                return {data: response.data, status: response.status};
            })
            .catch((error) => {
                return {
                    data: error.response.data
                };
            });
        }
    } else if (resource === "batch") {
        params.data.vendor = params.data.batchVendor;
        params.data.category = params.data.categories[0].name;
        const published = params.data?.published ? params.data.published : false;
        const productId = params.data.id;
        const product = await parseProductBeforePost(params.data)
        product.published = published;
        product.id = productId;
        product.user = user?.displayName?.replace(/\s+/g, '-').toLowerCase() || user?.email?.split('@')[0].replace('.', '-').toLowerCase();;
        const folder = await getFolder(resource, 'update', product.id);
        const dataRef = ref(database, folder);
        return new Promise(
            (resolve, reject) => {
                set(dataRef, product)
                .then(() => {
                    resolve({data: product});
                })
                .catch((error) => {
                    console.error(error);
                    console.error(error.message);
                    console.error(error.stack);
                    reject (error);
                })
            });
    }
    

    const wooC = new WooCommerceAPI(wooConfig)
    const target = resource + '/' + params.id;
    
    return wooC.put(target, product)
        .then(async (response) => {
            // Successful request
            const createdProduct = resource === "products" ? await parseProductAfterGet(response.data) : response.data;
            return {
                data: createdProduct
            };
        })
        .catch((error) => {
            // Invalid request, for 4xx and 5xx statuses
            console.error(product.id,':',error);
            console.error(error.message);
            
            let errorMsg = { id: product.id, status: error.message ,message: error.response.data, code: error.response.status}
            console.error(errorMsg);
            return { data: errorMsg };
        })
        .finally(() => {
            // Always executed.
        });
}
export async function updateMany(resource, params){
    // wordpress tai api-kirjapino-fi
    // batch update
    return Promise.resolve(resource);
}

export async function deleteMany(resource, params){
    return Promise.resolve(resource);
}

export async function deleteOne(resource, params){
    // wordpress
    if (resource === "batch") {
        const id = params.id.toString();
        const folder = await getFolder(resource, 'delete', id);
        const dataRef = ref(database, folder);
        return new Promise(
            (resolve, reject) => {
                set(dataRef, null)
                .then(() => {
                    resolve({data: {id: params.id}});
                })
                .catch((error) => {
                    console.error(error);
                    console.error(error.message);
                    console.error(error.stack);
                    reject (error);
                })
            });
    }
    return Promise.resolve(
        WooCommerce.delete(resource + '/' + params.id, {force: true})
        .then((response) => {

            return{data: response.data, status: response.status};
        })
        .catch((error) => {
            return {
                data: error.response.data
            };
        })
    );
}
export default {getList, getMany, getManyReference, getOne, update, updateMany, create, deleteMany, delete: deleteOne };
