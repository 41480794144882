import React, { useContext, useEffect, useState } from "react";
import { UserRoleContext } from '../UserRoleContext';
import productTemplate from "../data/productTemplate.json";
import {
    ArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    Create,
    ImageField,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
} from "react-admin";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { getEditions } from "../helpers/getEditions";
import { getYears } from "../helpers/getYears";
import categories from "../data/categories.json";
import series from "../data/series.json";
import languages from "../data/languages.json"
import binding from "../data/binding.json";
import condition from "../data/condition.json";


const postDefaultValue = () => (productTemplate);
const isRequired = required("Kenttä on pakollinen.")

const editions = getEditions();
const years = getYears();


const ImageButton = () => (
    <Button variant="contained">Lisää kuva..</Button>
);

export const BatchProductCreateView = (props) => 
{
    const { vendor } = useContext(UserRoleContext);
    const [userVendors, setUserVendors] = useState([]);

    useEffect(() => {
        if (vendor) {
            const vendorsList = vendor.name || [];
            const formattedVendors = vendorsList.map((vendorName, index) => ({
                id: index,
                name: vendorName
            }));
            setUserVendors(formattedVendors);
        }
    }, [vendor]);
    return (
    <Create 
    redirect={false}
    title="Lisää kirja" 
    {...props} >

        <SimpleForm defaultValues={postDefaultValue} variant="outlined">
                <Grid container alignItems="flex-start" spacing={1} >
                    
                    <Grid item sm={12} xs={12} md={2}>
                        <DateInput
                        validate={isRequired}
                        fullWidth label="Julkaisupäivä" 
                        source="release_date" 
                        defaultValue={new Date()}
                        />
                        <SelectInput 
                        validate={isRequired} 
                        label="Myyjä" 
                        source="batchVendor" 
                        optionValue="name" 
                        choices={userVendors} 
                        />
                        </Grid>
                        <Grid item sm={12} xs={12} md={2}>
                        <ImageInput
                            fullWidth
                            variant="outlined"
                            source="image"
                            label="Kuva"
                            accept="image/*"
                            validate={isRequired}
                            placeholder={<ImageButton />}>
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    </Grid>
                    <Grid item sm={12} xs={12} md={2}>
                    
                        <RadioButtonGroupInput 
                        label="Varaston tila" 
                        source="stock_status" 
                        choices={[
                                    { id: 'instock', name: 'Myynnissä' },
                                    { id: 'outofstock', name: 'Ei myynnissä' },
                                ]} 
                        />
                        
                    </Grid>
                    <Grid item sm={12} xs={12} md={4}>
                        <TextInput fullWidth validate={isRequired} label="Nimi" source="name" />
                        <TextInput fullWidth label="Tekijä" source="author" />
                    </Grid>
                    <Grid item sm={12} xs={12} md={2}>
                    <NumberInput
                    validate={isRequired} 
                     
                    min="1" 
                    label="Hinta (€)"
                    options={{ style: 'currency', currency: 'EUR' }}
                    source="price" />
                    <TextInput
                    label="Sivumäärä"  
                    source="pages"
                     />
                </Grid>
                </Grid>
            <Grid container alignItems="flex-start" spacing={2} > 
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput 
                        label="Kustantaja" 
                        source="publisher"
                    />
                </Grid>
                <Grid item sm={12} xs={12} md={2} >
                    <NumberInput
                        validate={isRequired} 
                        min="0" 
                        initialValue="1"  
                        label="Määrä" 
                        source="stock_quantity" 
                    />
                </Grid>
                <Grid item sm={12} xs={12} md={3}> 
                         <ArrayInput  label={false} source="category">
                           <AutocompleteInput
                           fullWidth
                           validate={isRequired}
                                label="Osasto"
                                allowEmpty="false"
                                optionValue="name"
                                initialValue="Kaunokirjallisuus"
                                source="name"
                                choices={categories}
                            />
                        </ArrayInput>
                </Grid>
                <Grid item sm={12} xs={12} md={4}>
                    <TextInput className="multiline-input" fullWidth multiline minRows={2} label="Kuvaus" source="description" />
                </Grid>
                </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
                <Grid item sm={12} xs={12} md={3}>
                <AutocompleteInput  label="Sarja" optionValue="name" source="series" choices={series}/>
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <TextInput label="Painopaikka" source="print_place" />
                    </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteArrayInput   label="Painos" source="edition" choices={editions}/>

                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <AutocompleteArrayInput 
                translateChoice={false} 
                emptyText="" 
                emptyValue={0} 
                label="Painovuosi" 
                 
                type="number" 
                source="year" 
                choices={years}
                aria-sort="descending"
                />
                    
                </Grid>
                
               
                
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} >
            <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteArrayInput optionValue="name" initialValue="suomi" label="Kieli" source="language" choices={languages}/>
                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteInput optionValue="name" initialValue="K4 (erinomainen)" label="Kuntoluokka" source="condition" choices={condition}/>
                    </Grid>
                <Grid item sm={12} xs={12} md={3}>
                    <AutocompleteInput optionValue="name" label="Sidosasu" initialValue="Sidottu, ei kansipaperia" source="layout" choices={binding}/>
                    </Grid>
                
                
            </Grid>
            <Grid container alignItems="flex-start" spacing={1}>
    
                    <Grid item sm={12} xs={12} md={3}>
                    <TextInput label="Varastopaikka" source="storage" />
                    </Grid>
                    <Grid item sm={12} xs={12} md={3}>
                    <TextInput validate={isRequired} label="Hylly" source="shelf" />
                </Grid>
                    <Grid item sm={12} xs={12} md={3}>
                <TextInput label="ISBN" source="isbn" />

                </Grid>
                <Grid item sm={12} xs={12} md={3}>
                <BooleanInput
                label="Lisätäänkö Antikvaariin?"
                defaultValue={true}
                source="targets" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
                            }