//import { v4 as uuidv4 } from 'uuid';
import {  FirebaseAuthProvider } from "react-admin-firebase";
import { firebaseConfig as config } from '../FIREBASE_CONFIG';
import { storageUploader } from "./storageUploader";

const authProvider = FirebaseAuthProvider(config);


const vendors = require('../config/vendors.json');
const productTemplate = require('../data/productTemplate.json');
const ImportedProduct = require('../data/importedProductTemplate.json');
const categories = require('../data/categories.json');
const mapping = require('../data/mapping.json');

const bucketUrl = 'https://storage.googleapis.com/prismatic-canto-249208.appspot.com/';

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1): '';
}

function getAttributeValue(attributes, id) {
  if (attributes.find((attribute) => attribute.id === id)) {
    const attribute = attributes.find((attribute) => attribute.id === id);
    const options = attribute.options;
    if (options && options.length > 0) {
      let rawOptions = options[0];
      rawOptions = id === 1 ? rawOptions.toLowerCase() : rawOptions;
      return rawOptions !== undefined ? rawOptions.replace(/(&amp;)/gm, "&") : "";
    } else {
      return "";
    }
  }
  return id !== 16 ? "" : true;
}

/** 
  function getEdition (attributes, id) {
    if (attributes.find(attribute => attribute.id === id)) {
      const editionOptions = attributes.find(attribute => attribute.id === id).options;
      const editions = [];
        for (let option of editionOptions){
          const rawEdition = option.toString();
          editions.push(rawEdition);  
        } 
      return editions;
    } else {
      return '';
    }
  }
*/
  async function getCategory(categoryStr) {
    if (categories.find(category => category.name === categoryStr)) {
        const foundCategory = categories.find(category => category.name === categoryStr)
        return Promise.resolve(foundCategory.id);
    } else {
        // Return Kaunokirjallisuus ID by default
        return Promise.resolve(4960);
    }
  }


export async function parseProductListAfterGet(products) {
    let parsedProducts = [];
    const user  = await authProvider.checkAuth();
    const vendorUid = user.uid;
    const vendor = vendors[vendorUid].name;
    for (let product of products){
      const attributes = product.attributes
      const productVendor = await getAttributeValue(attributes, 14).toString();
      if(vendor.includes(productVendor)){
        const parsedProduct = await parseProductAfterGet(product);
        parsedProducts.push(parsedProduct);
      }
  }
  return Promise.resolve(parsedProducts);
}


async function getFormattedDateTime() {
  const now = new Date();
  const timestamp = now.getTime(); // Get the current timestamp
  const random = Math.floor((Math.random() * timestamp) % 1000).toString().padStart(3, '0'); // Use timestamp as seed for random number
  const year = now.getFullYear().toString().slice(-2); // Get last two digits of the year
  const formattedDateTime = now.toLocaleString('fi-FI', {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    millisecond: '2-digit',
    hour12: false // Use 24-hour format
  }).replace(/[^0-9]/g, ''); // Remove non-numeric characters
  
  return `${year}${formattedDateTime}-${random}`;
}



async function getSku(category) {
  const prefix = category ? category.substring(0, 3).replace(/[äöå]/gi, 'o').toUpperCase() + '-' : 'KIR-';
  return prefix + await getFormattedDateTime();
}

export async function parseProductBeforePost(product){
  let imageModifiedDate = undefined;
  if (product.images && product.images[0]) {
        imageModifiedDate = product.images[0].date_modified;
        delete product.images[0].id;
        delete product.images[0].date_created;
        delete product.images[0].date_created_gmt;
        delete product.images[0].date_modified;
        delete product.images[0].date_modified_gmt;
    }
    if (product?.image?.src || imageModifiedDate === undefined) {
      imageModifiedDate = new Date().toISOString().replace(/\.\d{3}Z$/, 'Z');
    }
    const imageModifiedDateInMilliseconds = new Date(imageModifiedDate).getTime(); // Convert the date to a timestamp in milliseconds
    delete product.antikvaariId;
    const parsedProduct = {...productTemplate};
    const attributes = {...productTemplate.attributes};
    for (const key in parsedProduct) {
        parsedProduct[key] = product[key];
    }
    parsedProduct.category = product.category || '';
    parsedProduct.description = product.description || '';
    parsedProduct.short_description = product.short_description || '';
    parsedProduct.sold_individually = false;
    parsedProduct.manage_stock = true;
    parsedProduct.sku = product?.sku || await getSku(parsedProduct.category);
    parsedProduct.name = product.name.trim();
    parsedProduct.price = product?.price.toString();
    parsedProduct.regular_price = product?.price.toString();
    parsedProduct.stock_quantity = product?.stock_quantity ? parseInt(product.stock_quantity): 0;
    parsedProduct.stock_status = product?.stock_status || 'instock';
    parsedProduct.catalog_visibility = parsedProduct.stock_quantity > 0 && parsedProduct.stock_status === 'instock' ? 'visible' : 'hidden'; 
    parsedProduct.type = 'simple';
    const categoryId = await getCategory(product?.category?.toString() || '');

    parsedProduct.categories = product?.category ? [{id: categoryId, name: product.category}] : [{id: 4960}];
    const imageNameString = imageModifiedDateInMilliseconds + '-' + parsedProduct?.sku.replace(/[^a-z0-9]+|\s+/gmi, "_").replace(/_+/g, "_").substring(0, 50);
    const attributeMappings = [  'author',  'publisher',  'edition',  'year',  'pages',  'layout',  'language',  'condition',  'shelf',  'id_number',  'print_place',  'series',  'storage',  'vendor',  'isbn'];

    for (let i = 0; i < attributeMappings.length; i++) {
      const propName = attributeMappings[i];
      const propValue = product[propName]?.toString() || '';
      attributes[i].options[0] = propValue;
    }
    parsedProduct.tags = [];
    // Set the target attribute option to "antikvaari" if targets is true
    attributes[15].options[0] = product.targets ? "antikvaari" : "false";
    parsedProduct.attributes = attributes;
    if (product.image && product.image?.src) {
        const imageTitleArray = product.image.title ? product.image.title.split('.'): product.image[0].name.split('.');
        const imageName = imageNameString + '.' + imageTitleArray[imageTitleArray.length - 1];
        const uploadedImageUrl = await storageUploader(product.image, imageName);
        parsedProduct.images = [{
          name: imageName, 
          alt: product.name ? product.name: '', 
          src: bucketUrl + uploadedImageUrl
        }];

    } else if (!parsedProduct.images?.[0]) {
        delete parsedProduct.images;
    }
    //delete parsedProduct.id;
    parsedProduct.release_date = product.release_date ? product.release_date : '';
    return Promise.resolve(parsedProduct);
}

export async function parseProductAfterGet(product){
    // Replace the first image's source URL
    if (product?.images?.[0]) {
      product.images[0].src = product.images[0].src.replace(/\\\//g, '/');
      product.imgSrc = product.images[0].src;
    }
    let attributes = product?.attributes;
    product.image = product.images;
    product.release_date = product.release_date ? product.release_date : '';
    product.imgSrc = product.images && product.images.length > 0? product.images[0].src : '';
    product.category = product.category || product.categories[0].name;
    product.author = getAttributeValue(attributes, 8).toString();
    product.publisher = getAttributeValue(attributes, 3).toString();
    product.edition = getAttributeValue(attributes, 4).split(',');
    product.year = getAttributeValue(attributes, 5).split(','); 
    product.pages = getAttributeValue(attributes, 7).split(',');
    product.layout = capitalizeFirstLetter(getAttributeValue(attributes, 6).toString());
    product.language = getAttributeValue(attributes, 1).split(',');
    const condition = getAttributeValue(attributes, 2).toString();
    product.condition = mapping.condition[condition] ? mapping.condition[condition]: 'K3 (hyvä)';
    product.shelf = getAttributeValue(attributes, 11).toString();
    product.id_number = getAttributeValue(attributes, 13).toString() ;
    product.print_place = getAttributeValue(attributes, 10).toString() ;
    product.series = getAttributeValue(attributes, 9).toString() ;
    product.storage = getAttributeValue(attributes, 12).toString();
    product.vendor = getAttributeValue(attributes, 14).toString();
    product.batchVendor = product?.batchVendor || getAttributeValue(attributes, 14);
    product.isbn = getAttributeValue(attributes, 15).toString();
    const targetValue = getAttributeValue(attributes, 16).toString();
    product.targets = targetValue === "antikvaari" || targetValue === "true" ? true : false;
    product.price = product.regular_price ? product.regular_price : undefined; 
    product.sku = product?.sku ? product.sku : '';
    return Promise.resolve(product);
}


export function parseProductAfterSearch(product){
  let parsedProduct; 
  parsedProduct = productTemplate;
  let attributes = productTemplate.attributes;
  parsedProduct.id = product.post_id;
  parsedProduct.name = product.post_title;
  parsedProduct.sku = product.meta._sku[0].value;
  parsedProduct.stock_quantity = parseInt(product.meta._stock[0].value);
  parsedProduct.stock_status = product.meta._stock_status[0].value;
  parsedProduct.image = product.thumbnail ? product.thumbnail.src:'';
  parsedProduct.images[0].src = product.thumbnail ? product.thumbnail.src:'';
  parsedProduct.category = product.terms.product_cat ? product.terms.product_cat[0].term_id:'';
  parsedProduct.author = product.terms.pa_tekija ? product.terms.pa_tekija[0].name:'';
  parsedProduct.publisher = product.terms.pa_kustantaja ? product.terms.pa_kustantaja[0].name:'';
  parsedProduct.edition = product.terms.pa_painos ? product.terms.pa_painos[0].name:'';
  parsedProduct.year = product.terms.pa_painovuosi ? product.terms.pa_painovuosi[0].name:''; 
  parsedProduct.pages = product.terms.pa_sivumaara ? product.terms.pa_sivumaara[0].name:'';
  parsedProduct.layout = product.terms.pa_sidosasu ? product.terms.pa_sidosasu[0].name:'';
  parsedProduct.language = product.terms.pa_kieli ? product.terms.pa_kieli[0].name:'';
  parsedProduct.condition = product.terms.pa_kuntoluokka ? product.terms.pa_kuntoluokka[0].name:'';
  parsedProduct.shelf = product.terms.pa_hylly ? product.terms.pa_hylly[0].name:'';
  parsedProduct.id_number = product.terms.pa_identifiointinumero ?  product.terms.pa_identifiointinumero[0].name:'';
  parsedProduct.print_place = product.terms.pa_painopaikka ? product.terms.pa_painopaikka[0].name:'';
  parsedProduct.series = product.terms.pa_sarja ? product.terms.pa_sarja[0].name:'';
  parsedProduct.storage = product.terms.pa_varasto ? product.terms.pa_varasto[0].name:'';
  parsedProduct.vendor = product.terms.pa_vendor ?  product.terms.pa_vendor[0].name:'';
  parsedProduct.isbn = product.terms.pa_isbn ?  product.terms.pa_isbn[0].name: '';
  attributes[0].options[0] = parsedProduct.author ?  parsedProduct.author.toString() : '';
  attributes[1].options[0] = parsedProduct.publisher ?  parsedProduct.publisher.toString(): '';
  attributes[2].options[0] = parsedProduct.edition ? parsedProduct.edition.toString() : '';
  attributes[3].options[0] = parsedProduct.year ?  parsedProduct.year.toString(): '';
  attributes[4].options[0] = parsedProduct.pages ?  parsedProduct.pages.toString(): '';
  attributes[5].options[0] = parsedProduct.layout ? parsedProduct.layout.toString(): '';
  attributes[6].options[0] = parsedProduct.language ? parsedProduct.language.toString(): '';
  attributes[7].options[0] = parsedProduct.condition ? parsedProduct.condition.toString(): '';
  attributes[8].options[0] = parsedProduct.shelf ? parsedProduct.shelf.toString(): '';
  attributes[9].options[0] = parsedProduct.id_number ? parsedProduct.id_number.toString(): '';
  attributes[10].options[0] = parsedProduct.print_place ? parsedProduct.print_place.toString(): '';
  attributes[11].options[0] = parsedProduct.series ?  parsedProduct.series.toString(): '';
  attributes[12].options[0] = parsedProduct.storage ?  parsedProduct.storage.toString(): '';
  attributes[13].options[0] = parsedProduct.vendor ? parsedProduct.vendor.toString(): '';
  attributes[14].options[0] = parsedProduct.isbn ? parsedProduct.isbn.toString(): '';
  parsedProduct.attributes = attributes;
  return parsedProduct;
}

export function parseImportedProduct(){

  const product = productTemplate;
  product.name = ImportedProduct.Nimi;

  product.images[0].src = 'https://www.antikvaari.fi/imagesproduct/' + ImportedProduct.kuvatieto;
  product.attributes[0].options[0] = ImportedProduct.Tekija;

}